<template>
  <v-container class="fill-height" fluid>
    <template v-if="isMobile">
      <v-card flat width="98%" class="mb-6" v-if="locationList">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card width="100%">
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="slide in documentsImages"
            :key="slide.id"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="5" v-if="locationList">
          <v-card class="ml-12" outlined tile>
            <map-view :list="locationList"></map-view>
          </v-card>
        </v-col>
        <v-col md="6" offset-md="1">
          <v-card class="mr-12" outlined tile>
            <v-carousel
              cycle
              height="330"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="slide in documentsImages"
                :key="slide.id"
                :src="slide.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row align="center" justify="center" class="mt-4" no-gutters>
      <v-col cols="12">
        <v-card>
          <v-card-title class="headline display-1">
            {{ isMobile === true ? "Equipements" : "Liste des équipements" }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('tableStatus.searchAll')"
              single-line
              hide-details
              class="mt-n2"
            >
            </v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="list"
              item-key="name"
              class="table is_bordered"
              :loading="loading"
              :search="search"
              :page.sync="page"
              :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @click:row="clicked"
              group-by="entity"
              @page-count="pageCount = $event"
            >
              <template v-slot:group.header="{ toggle, group, isOpen }">
                <td colspan="12" class="pl-0">
                  <v-btn @click="toggle()" icon>
                    <v-icon
                      >mdi-{{
                        isOpen
                          ? "arrow-up-drop-circle-outline"
                          : "arrow-down-drop-circle-outline"
                      }}</v-icon
                    >
                  </v-btn>
                  <span class="primary--text">{{ group }}</span>
                </td>
              </template>
              <template v-if="isMobile" v-slot:item="{ item }">
                <mobile-row
                  :item="item"
                  :headers="mobileHeaders"
                  @expand="expandRow"
                  :is-expanded="shoulExpand(item.id)"
                  @n-expand="removeFromExpanded"
                  @row-clicked="clicked"
                >
                </mobile-row>
              </template>
              <template v-else>
                <c-row @click="clicked(item)"> </c-row>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"> </v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { UserSessionData } from "../mixins/userData";
import moment from "../plugins/moment";
import MapView from "../components/Map";
import { Icon } from "leaflet";
import USER_API from "../api/user";
import MobileRow from "./EsrfMobileRow";
import { statusListEquipment, remove, flatten } from "../utils";
import { PORTAL_CONFIG } from "../config";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  name: "EsrfAssets",
  components: { MapView, MobileRow },
  data() {
    return {
      page: 1,
      pageCount: 0,
      search: "",
      expanded: [],
      loading: false,
      mobileHeaders: [
        {
          text: this.$t("header.note"),
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("header.measurement"),
          align: "left",
          sortable: false,
          value: "dimension_alim"
        },
        {
          text: this.$t("header.residual_life"),
          align: "right",
          sortable: false,
          value: "duree"
        }
      ],
      headers: [
        {
          text: this.$t("header.name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("header.shape"),
          align: "left",
          sortable: false,
          value: "operationnelName"
        },
        {
          text: this.$t("header.note"),
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("header.measurement"),
          align: "left",
          sortable: false,
          value: "dimension_alim"
        },
        {
          text: this.$t("header.year_installation"),
          align: "right",
          sortable: false,
          value: "creationdate"
        },
        {
          text: this.$t("header.residual_life"),
          align: "right",
          sortable: false,
          value: "duree"
        },
        {
          text: this.$t("header.entity"),
          align: "right",
          sortable: false,
          value: "entity"
        }
      ]
    };
  },
  mixins: [UserSessionData],

  computed: {
    ...mapGetters([
      "getTreeData",
      "getDocumentsImages",
      "getAssets",
      "getUserToken"
    ]),
    treeAssetData() {
      const firstAsset = this.getTreeData[0];
      return firstAsset.level === 2
        ? flatten(
            this.getTreeData.map(child => child.children),
            2
          )
        : this.getTreeData;
    },

    locationList() {
      const assetID = this.$route.params.assetID;

      const entities = assetID
        ? this.treeAssetData.find(el => el.id === +assetID).children
        : this.treeAssetData;
      return entities
        .filter(el => el.level === 4)
        .map(el => {
          const latlng = this.getLatLng(el);
          return {
            name: el.name,
            lat: latlng.lat,
            lng: latlng.lng,
            link: { name: "assets.show", params: { id: el.id } }
          };
        });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    },
    documentsImages() {
      const assetID = this.$route.params.assetID;
      if (assetID) {
        const asset = this.treeAssetData.find(el => el.id === +assetID);
        const entitiesId = asset.children.map(entity => entity.id);
        return this.getDocumentsImages.filter(doc => {
          return (
            doc.entities_id === assetID ||
            entitiesId.includes(doc.entities_id) ||
            doc.entities_id === 1
          );
        });
      }
      return this.getDocumentsImages;
    }
  },
  methods: {
    getStatus(status) {
      return status
        ? statusListEquipment.find(el => el.serial === status)
        : { color: "" };
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    clicked: function(item) {
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/itemtype.php?user=" +
          this.getUserToken +
          "&ept=" +
          item.code_pi +
          "&entity=" +
          item.entities_id
      );
    },
    loadData() {
      if (this.isAuth) {
        this.loading = true;
        USER_API.changeActiveEntity()
          .then(() => {
            USER_API.getEquipment("Alim").then(response => {
              this.list = response.data;
              const size = this.list.length;
              if (size > 0) {
                //pour écrire le tableau
                for (let i = 0; i < size; i++) {
                  if (this.list[i].operationnel === 1) {
                    this.list[i].operationnelName = "OK";
                    //pour avoir le statut dans le tableau
                    this.list[i].operationnel = 11;
                  } else {
                    this.list[i].operationnelName = "NOK";
                    //pour avoir le statut dans le tableau
                    this.list[i].operationnel = 10;
                  }
                  this.list[i].name = this.list[i].name.toUpperCase();
                  const size2 = this.getAssets.length;
                  for (let j = 0; j < size2; j++) {
                    if (this.getAssets[j].id === this.list[i].entities_id) {
                      this.list[i].entity = this.getAssets[j].name;
                    }
                  }
                  this.list[i].dimension_alim = this.list[i].dimension_alim;
                  if (this.list[i].expirationdate != null) {
                    this.list[i].duree =
                      this.list[i].expirationdate.split("-")[0] -
                      moment().format("YYYY") +
                      " an(s)";
                  }
                }
              }
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>

<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 200px;
  --primary: #44b02a;
}

tr:nth-of-type(even) {
  background-color: #f5f5f5;
}

.t-row {
  color: #2d2d2d;
}
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px !important;
}
.table.is-bordered th {
  border-width: 1px !important;
  color: #363636 !important;
}
@media only screen and (max-width: 799px) and (min-width: 240px) {
  .table th {
    background-color: var(--primary) !important;
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
    height: 2px !important;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
    background-color: var(--primary);
    color: white !important;
  }
}
.v-data-table table td {
  border-width: 1px !important;
  border: 1px solid #dbdbdb !important;
  vertical-align: middle !important;
}
</style>
