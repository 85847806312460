<template>
  <v-card>
    <template v-if="show">
      <template v-if="isEsrf"><esrf-assets> </esrf-assets></template>
      <template v-else-if="is2alpes"
        ><deuxalpes-assets> </deuxalpes-assets
      ></template>
      <template v-else-if="isCcct"><ccct-assets> </ccct-assets></template>
      <template v-else-if="isInfo"><info-assets> </info-assets></template>
      <template v-else>
        <assets-default> </assets-default>
      </template>
    </template>
    <template v-else>
      <assets-admin> </assets-admin>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import assetsDefault from "./AssetDefault";
import assetsAdmin from "./AssetAdmin";
import EsrfAssets from "../clients/EsrfAsset";
import DeuxalpesAssets from "../clients/DeuxalpesAsset";
import CcctAssets from "../clients/CcctAsset";
import InfoAssets from "../clients/InfoAsset";

import { PORTAL_CONFIG } from "../config";

export default {
  name: "assets",
  components: {
    assetsDefault,
    EsrfAssets,
    DeuxalpesAssets,
    CcctAssets,
    InfoAssets,
    assetsAdmin
  },
  data() {
    return {
      locationList: []
    };
  },
  created() {
    //this.loadData();
  },
  computed: {
    ...mapGetters(["isAdminUser", "getUserId", "getUserProfiles"]),
    isEsrf() {
      return this.$route.params.assetID === 79;
    },
    isInfo() {
      return this.getUserProfiles[0] === 22;
    },
    is2alpes() {
      return (
        this.$route.params.assetID === 102 ||
        this.$route.params.assetID === 105 ||
        this.getUserId === 68 ||
        this.getUserId === 69 ||
        this.getUserId === 70
      );
    },
    isCcct() {
      return (
        this.$route.params.assetID === 211 ||
        this.$route.params.assetID === 214 ||
        //this.getUserId === 81 ||
        //this.getUserId === 69 ||
        this.getUserId === 80
      );
    },
    show() {
      return !this.isAdminUser || this.$route.params.assetID;
    }
  },
  methods: {
    loadData() {
      fetch(`${PORTAL_CONFIG.SERVER_WEB}/dev/where.php`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          this.locationList = result;
        });
    }
  },
  watch: {
    $route() {
      //this.loadData();
    }
  }
};
</script>
