<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card class="elevation-3 my-6">
    <v-col lg="12" class="px-0 px-md-3">
      <v-card v-if="isMobile" flat>
        <v-row no-gutters class="pa-2">
          <v-col cols="9">
            <v-card-title class="headline display-1 my-n3">
              Interventions
            </v-card-title>
          </v-col>
          <v-row>
            <v-col md="6" sm="12" class="mt-n2 mb-n5">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Recherche"
                single-line
                hide-details
                class="mt-n2"
              >
              </v-text-field>
            </v-col>
            <v-col md="6" sm="12" class="mt-n5 mb-n5">
              <v-daterange
                v-model="dateRange"
                display-format="dd-MM-yyyy"
                no-title
                :menu-props="menuProps"
                :presets="presets"
                start-label="Début"
                end-label="Fin"
                separator-label="/"
                :locale="$root.$i18n.locale"
              ></v-daterange>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
      <v-card flat v-else>
        <v-card-title class="headline display-1">
          Liste de toutes les interventions
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="elevation-2 mt-2 grey lighten-5">
          <v-row>
            <v-col md="5" sm="12" class="mt-n2 mb-n5">
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('tableStatus.searchAll')"
                single-line
                hide-details
                class="mt-n2"
              >
              </v-text-field>
            </v-col>
            <v-col md="5" offset="2" sm="12" class="mt-n5 mb-n5">
              <v-daterange
                v-model="dateRange"
                display-format="dd-MM-yyyy"
                no-title
                :menu-props="menuProps"
                :presets="presets"
                :start-label="$t('labels.start_date')"
                :end-label="$t('labels.end_date')"
                :separator-label="$t('labels.to')"
                :locale="$root.$i18n.locale"
              ></v-daterange>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="px-2 px-md-4 mb-6">
        <v-data-table
          :key="updateKey"
          :headers="headers"
          :items="filteredItems"
          item-key="id"
          :loading="loading"
          class="table is_bordered"
          sort-by="date"
          sort-desc
          group-by="equipment"
          :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
          :group-desc="false"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:group.header="{ toggle, group, isOpen }">
            <td colspan="12" class="pl-0">
              <v-btn @click="toggle()" icon>
                <v-icon
                  >mdi-{{
                    isOpen
                      ? "arrow-up-drop-circle-outline"
                      : "arrow-down-drop-circle-outline"
                  }}</v-icon
                >
              </v-btn>
              <span class="primary--text">{{ group }}</span>
            </td>
          </template>

          <template v-slot:item="{ item, isMobile }">
            <mobile-row-presta
              :item="item"
              :headers="mobileHeaders"
              v-if="isMobile"
              @expand="expandRow"
              @row-clicked="clicked"
              :is-expanded="shoulExpand(item.id)"
              @n-expand="removeFromExpanded"
            >
              <template v-slot:content="{ item }">
                <div
                  class="incident description"
                  :class="{ 'test-class': isMobile }"
                  v-if="item.content"
                  v-html="decodeContent(item.content)"
                ></div>
              </template>
              <template v-slot:name="{ item }">
                {{ JSON.parse(item.name).titre }}
              </template>
              <template v-slot:statusName="{ item }">
                <v-chip
                  label
                  small
                  :color="getStatus(item.status).color"
                  text-color="white"
                >
                  {{ getStatus(item.status).name }}
                </v-chip>
              </template>
            </mobile-row-presta>
            <c-row
              v-else
              :item="item"
              :headers="headers"
              rtl
              @click="clicked(item)"
            >
              <template v-slot:id="{ item }">
                {{ formatID(item.id) }}
              </template>
              <template v-slot:name="{ item }">
                {{ JSON.parse(item.name).titre }}
              </template>
              <template v-slot:content="{ item }">
                <div
                  class="incident description"
                  :class="{ 'test-class': isMobile }"
                  v-if="item.content"
                  v-html="decodeContent(item.content)"
                ></div>
              </template>
              <template v-slot:date="{ item }">
                {{
                  item.date ? moment(item.date).format("DD-MM-YYYY HH:mm") : ""
                }}
              </template>
              <template v-slot:statusName="{ item }">
                <v-chip
                  label
                  small
                  :color="getStatus(item.status).color"
                  text-color="white"
                >
                  {{ getStatus(item.status).name }}
                </v-chip>
              </template>
              <template v-slot:users_id="{ item }">
                {{ triche(getAttribUser(item.id)) }}
              </template>
              <template v-slot:equipment="{ item }">
                {{ hideElt(item.equipment) }} {{ priority(item.priority) }}
              </template>
            </c-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
    </v-col>
  </v-card>
</template>
<script>
import {
  formatID,
  remove,
  hideElt,
  triche,
  priority,
  uniqueElementBy,
  statusList
  //blobToImage
} from "../utils";
import { Decoder } from "../mixins/decodeContent";
import moment from "../plugins/moment";
import Row from "../components/Row";
import USER_API from "../api/user";
import MobileRowPresta from "../components/prestation/MobileRowPresta";
import { mapGetters } from "vuex";
import VDaterange from "../components/VDaterange";

export default {
  name: "List",
  props: {
    tickets: {
      type: Array,
      default() {
        return [];
      }
    },
    loaded: {
      type: Boolean,
      default: false
    }
  },
  components: { [Row.name]: Row, MobileRowPresta, VDaterange },
  data() {
    return {
      list: [],
      search: "",
      ticketsUsers: [],
      expanded: [],
      selectedTicket: undefined,
      updateKey: 0,
      selectedDate: null,
      docFilterDialog: false,
      dialog: false,
      loading: false,
      selectedPerimetre: [],
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: true,
          value: "id",
          class: "t-row"
        },
        {
          text: this.$t("header.perimeter"),
          align: "center",
          sortable: false,
          value: "name",
          class: "t-row"
        },
        {
          text: this.$t("header.date"),
          align: "center",
          sortable: true,
          value: "date",
          class: "t-row"
        },
        {
          text: this.$t("header.status"),
          align: "center",
          sortable: false,
          value: "statusName",
          class: "t-row"
        },
        {
          text: this.$t("header.description"),
          align: "center",
          sortable: false,
          value: "content",
          class: "t-row"
        },
        {
          text: "Resp.",
          align: "center",
          sortable: false,
          value: "users_id",
          class: "t-row"
        },
        {
          text: "Priorité",
          align: "center",
          sortable: false,
          value: "equipment",
          class: "t-row"
        }
      ],
      mobileHeaders: [
        {
          text: this.$t("header.date"),
          align: "center",
          sortable: true,
          value: "date",
          class: "t-row"
        },
        {
          text: this.$t("header.status"),
          align: "center",
          sortable: false,
          value: "statusName",
          class: "t-row"
        },
        {
          text: this.$t("header.description"),
          align: "center",
          sortable: false,
          value: "content",
          class: "t-row"
        },
        {
          text: "Equipement",
          align: "center",
          sortable: false,
          value: "equipment",
          class: "t-row"
        }
      ],
      dateMenu: false,
      dateFormatted: null,
      dateRange: {},
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        }
      ],
      documents: []
    };
  },
  //inject: ["getAttribUser"],
  provide() {
    return {
      getAttribUser: this.getAttribUser
    };
  },
  mixins: [Decoder],
  methods: {
    formatID,
    hideElt,
    priority,
    triche,
    moment,
    async loadData() {
      this.loading = true;
      this.ticketsUsers = (await USER_API.getTicketsUsers()).data;
      this.loading = false;
    },
    getStatus(status) {
      return status
        ? statusList(this.$root.$i18n.locale).find(el => el.status === status)
        : { name: "", color: "" };
    },
    getAttribUser(ticketId) {
      let ticketUser = this.ticketsUsers.find(
        user => user.tickets_id === ticketId
      );
      return ticketUser ? ticketUser.users_id : "";
    },
    customSort(items, index, sortDesc) {
      // The following is informations as far as I researched.
      // items: 'tickets' items
      // index: Enabled sort headers value. (black arrow status).
      // sortDesc: Whether enabled sort headers is desc
      if (!index[1]) return items;

      items.sort((a, b) => {
        const attribute = index[1];
        let sortA = a[attribute];
        let sortB = b[attribute];
        const dateAttributes = ["date", "attributdate", "date_mod"];
        if (dateAttributes.includes(attribute)) {
          if (!sortA) {
            sortA = "2010-01-01 00:00";
          }
          if (!sortB) {
            sortB = "2010-01-01 00:00";
          }
          const dateA = moment(sortA, "YYYY-MM-DD HH:mm:ss");
          const dateB = moment(sortB, "YYYY-MM-DD HH:mm:ss");

          if (sortDesc[0]) {
            if (dateB.isAfter(dateA)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          } else {
            if (dateA.isAfter(dateB)) {
              return 1;
            } else if (dateB.isAfter(dateA)) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });

      return items;
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    },
    clicked: function(row) {
      this.$router.push({ name: "lots.show", params: { id: row.entities_id } });
    },
    titleText(selectedTicket) {
      try {
        const title = this.getActiveEntity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        let assetTitle = "";
        if (this.$vuetify.breakpoint.smAndDown) {
          assetTitle =
            parentsEntities[2].slice(0, 10) +
            " > " +
            parentsEntities[3].slice(0, 10);

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment.slice(0, 10);
        } else {
          assetTitle = parentsEntities[2] + " > " + parentsEntities[3];

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment;
        }
        return assetTitle;
      } catch (error) {
        return "";
      }
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    loadEquiment() {
      this.tickets.forEach(ticket => {
        const equipment = this.getEquipments.filter(
          el => el.id == ticket.equipment
        );
        if (equipment && equipment.length > 0) {
          ticket.equipment = equipment[0].room
            ? equipment[0].room.split("_")[1] + "-" + equipment[0].name
            : equipment[0].name;
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getActiveEntity", "getDocumentsImages", "getEquipments"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 1000 : 30;
    },
    perimetres() {
      const names = this.tickets.map(ele => ele.name);
      const perimetres = uniqueElementBy(names, (a, b) => a === b);
      return perimetres;
    },
    filteredItems() {
      return this.tickets.filter(i => {
        const dateCreation = moment(i.date, "YYYY-MM-Do HH:mm ss").format(
          "YYYY-MM-DD"
        );
        return (
          (this.selectedPerimetre.length === 0 ||
            this.selectedPerimetre.includes(JSON.parse(i.name).titre)) &&
          this.filterByDate(dateCreation)
        );
      });
    }
  },
  created() {
    if (!this.isMobile) {
      this.loadData();
    }
  },
  mounted() {
    this.loadEquiment();
  },
  watch: {
    selectedDate() {
      this.dateFormatted = this.formatDate(this.selectedDate);
    }
  }
};
</script>
<style>
:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 200px;
}

tr:nth-of-type(even) {
  background-color: #f5f5f5;
}

.t-row {
  color: #2d2d2d;
}
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px !important;
}
.table.is-bordered th {
  border-width: 1px !important;
  color: #363636 !important;
}
@media only screen and (max-width: 799px) and (min-width: 240px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
    background-color: var(--primary);
    color: white !important;
  }
}
.v-data-table table td {
  border-width: 1px !important;
  border: 1px solid #dbdbdb !important;
  vertical-align: middle !important;
}
.incident p {
  margin-bottom: 0 !important;
}
.resolu {
  color: white !important;
}
.description {
  max-width: var(--desc-max-width);
  max-height: var(--max-height);
  overflow: auto;
}
</style>
