<template>
  <v-card>
    <v-container class="fill-height" fluid>
      <template v-if="isMobile">
        <v-card flat width="98%" class="mb-6" v-if="locationList">
          <map-view :list="locationList"></map-view>
        </v-card>
        <v-card width="100%">
          <v-carousel
            cycle
            height="330"
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="slide in documentsImages"
              :key="slide.id"
              :src="slide.image"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </template>
      <template v-else>
        <v-row class="mb-6" no-gutters>
          <v-col md="5" v-if="locationList">
            <v-card class="ml-12" outlined tile>
              <map-view :list="locationList"></map-view>
            </v-card>
          </v-col>
          <v-col md="6" offset-md="1">
            <v-card class="mr-12" outlined tile>
              <v-carousel
                cycle
                height="330"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="slide in documentsImages"
                  :key="slide.id"
                  :src="slide.image"
                ></v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-row align="center" justify="center" class="mt-4" no-gutters>
        <v-col cols="12">
          <v-card>
            <v-row no-gutters>
              <v-col cols="5">
                <v-card-title class="headline display-1">
                  {{ $t("asset.list_assets") }}
                </v-card-title>
              </v-col>
              <v-col cols="7">
                <v-card-title>
                  {{
                    getUserData.phone2 &&
                    JSON.parse(getUserData.phone2).solde_comptable
                      ? "Votre solde comptable : " +
                        formatValeur(
                          JSON.parse(getUserData.phone2).solde_comptable
                        ) +
                        " €"
                      : ""
                  }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-data-table
              locale="fr-FR"
              :headers="filteredHeader"
              :items="filteredItems"
              @click:row="clicked"
              item-key="id"
              :loading="loading"
              class="elevation-1 list__asset"
              id="assetsTable"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item="{ item }">
                <c-row
                  :item="item"
                  :headers="filteredHeader"
                  rtl
                  :class="{
                    'asset-level': item.level === 3 || item.level === 4
                  }"
                  ignore-null-val
                  @click="clicked(item)"
                >
                  <template v-slot:surfacemtwofield="{ item }">
                    <template v-if="item.level === 3 || item.level === 4">
                      {{
                        getChildrenData(item, "surfacemtwofield")
                          | number_format
                      }}
                    </template>
                    <template v-else>
                      <template v-if="!isNaN(item.surfacemtwofield)">
                        {{ item.surfacemtwofield | number_format }}
                      </template>
                      <template v-else> {{ item.surfacemtwofield }} </template>
                    </template>
                  </template>

                  <template v-if="isMobile" v-slot:etageportefield="{ item }">
                    {{
                      item.level === 3 || item.level === 4
                        ? `${item.town ? item.town : ""} (${
                            item.postcode ? formatCP(item.postcode) : "NA"
                          })`
                        : item.etageportefield
                    }}
                  </template>
                  <template v-else v-slot:etageportefield="{ item }">
                    {{
                      item.level === 3 || item.level === 4
                        ? `${item.address ? item.address : ""} ${
                            item.postcode ? item.postcode : ""
                          } ${item.town ? item.town : ""}`
                        : item.etageportefield
                    }}
                  </template>

                  <template v-slot:events="{ item }">
                    <div
                      class="incident description"
                      v-if="item.events"
                      v-html="item.events"
                    ></div>
                  </template>
                </c-row>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"> </v-pagination>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { LayoutOptions } from "../mixins/layouts";
import {
  loadStates,
  permissions,
  formatCP,
  formatValeur,
  sumBy,
  flatten,
  staticHeader
} from "../utils";
import { UserSessionData } from "../mixins/userData";
import { Decoder } from "../mixins/decodeContent";
import MapView from "../components/Map";

export default {
  name: "assetsDefault",
  components: { MapView },
  data() {
    return {
      treeData: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 30,
      items: [{ ...staticHeader(this.locale) }]
    };
  },
  mixins: [UserSessionData, Decoder, LayoutOptions],
  computed: {
    ...mapGetters([
      "getEntityLoadStatus",
      "getUserProfileStatus",
      "getTreeData",
      "getCharges",
      "getAllTickets",
      "getUserProfiles",
      "getDocuments",
      "getDocumentsImages",
      "getReservations",
      "getAssets"
    ]),
    filteredHeader() {
      let headers = [
        {
          text: this.$t("header.theme"),
          align: "left",
          sortable: false,
          value: "label",
          class: "primary white--text"
        }
      ];

      if (
        this.getUserProfiles &&
        permissions.profilePermission("generique", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "",
            align: "end",
            sortable: false,
            value: "surfacemtwofield",
            class: "border-r-0 primary white--text",
            isNumber: true
          },
          {
            text: this.$t("header.info"),
            align: "center",
            sortable: false,
            value: "nomcommercialfield",
            class: "border-l-0 primary white--text"
          },
          {
            text: this.$t("header.generics"),
            align: "start",
            sortable: false,
            value: "etageportefield",
            class: "border-l-0 primary white--text"
          }
        );
      }

      if (
        this.getUserProfiles &&
        permissions.profilePermission("engagement", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: this.$t("header.commitment"),
            align: "center",
            sortable: false,
            value: "datedbutdebailfield",
            class: "primary white--text"
          },
          {
            text: this.$t("header.lease"),
            align: "center",
            sortable: false,
            value: "datefindebailfield",
            class: "border-l-0 primary white--text"
          }
        );
      }

      if (
        this.getUserProfiles &&
        permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "",
            align: "end",
            sortable: false,
            value: "soldefield",
            class: "primary white--text is_number",
            isNumber: true
          },
          {
            text: this.$t("header.financial"),
            align: "end",
            sortable: false,
            value: "loyerannuelhtfield",
            class: "border-l-0 primary white--text is_number",
            isNumber: true
          },
          {
            text: "",
            align: "end",
            sortable: false,
            value: "charge",
            class: "border-l-0 primary white--text is_number",
            isNumber: true
          },
          {
            text: "",
            align: "end",
            sortable: false,
            value: "impot",
            class: "border-l-0 primary white--text is_number",
            isNumber: true
          }
        );
      }
      if (
        this.getUserProfiles &&
        !permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "",
            align: "end",
            sortable: false,
            value: "travaux1",
            class: "primary white--text is_number",
            isNumber: true
          },
          {
            text: this.$t("header.works"),
            align: "end",
            sortable: false,
            value: "investissement",
            class: "border-l-0 primary white--text is_number",
            isNumber: true
          },
          {
            text: "",
            align: "end",
            sortable: false,
            value: "travaux2",
            class: "border-l-0 primary white--text is_number",
            isNumber: true
          }
        );
      }

      return headers;
    },
    loading() {
      return this.getEntityLoadStatus === loadStates.PENDING;
    },
    filteredItems() {
      const assetID = this.$route.params.assetID;
      let items = [];
      items.push(this.items[0]);
      if (assetID) {
        const items2 = this.items.filter(el => el.entities_id == assetID);
        items2.forEach(item => {
          const children = this.items.filter(el => el.entities_id == item.id);
          items.push(item);
          children.forEach(child => {
            items.push(child);
          });
        });
      } else {
        items = this.items;
      }
      return items.filter(item => item.partiescommunefield !== 1);
    },
    treeAssetData() {
      const firstAsset = this.getTreeData[0];
      return firstAsset.level === 2
        ? flatten(this.getTreeData.map(child => child.children))
        : this.getTreeData;
    },
    locationList() {
      const assetID = this.$route.params.assetID;

      const entities = assetID
        ? this.treeAssetData.find(el => el.id === +assetID).children
        : this.treeAssetData;
      return entities
        .filter(
          el =>
            el.level === 4 &&
            this.getLatLng(el).lat + this.getLatLng(el).lng != 0
        )
        .map(el => {
          const latlng = this.getLatLng(el);
          return {
            name: el.name,
            lat: latlng.lat,
            lng: latlng.lng,
            link: { name: "assets.show", params: { id: el.id } }
          };
        });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    documentsImages() {
      const assetID = this.$route.params.assetID;
      if (assetID) {
        const asset = this.treeAssetData.find(el => el.id === +assetID);
        const entitiesId = asset.children.map(entity => entity.id);
        return this.getDocumentsImages.filter(doc => {
          return (
            doc.entities_id === assetID ||
            entitiesId.includes(doc.entities_id) ||
            doc.entities_id === 1
          );
        });
      }
      return this.getDocumentsImages;
    },
    locale() {
      return this.$root.$i18n.locale;
    }
  },
  methods: {
    formatCP,
    formatValeur,
    ...mapActions(["loadEntity", "loadUserProfile", "loadEntities"]),
    clicked: function(row) {
      if (!row.name) {
        return;
      } else if (row.level === 5) {
        this.$router.push({ name: "lots.show", params: { id: row.id } });
      } else if (row.level === 4) {
        this.$router.push({ name: "assets.show", params: { id: row.id } });
      } else {
        this.$router.push({
          name: "assets.index",
          params: { assetID: row.id }
        });
      }
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    getChildrenData(entity, key) {
      const filteredChild = entity.children.filter(
        child => child.partiescommunefield === 0
      );
      return sumBy(filteredChild, key);
    },
    resetItems() {
      this.items = [{ ...staticHeader(this.locale) }];
      this.getAssets.forEach(el => {
        this.items.push(el);
      });
    },
    isNaN
  },
  mounted() {
    this.getAssets.forEach(el => {
      this.items.push(el);
    });
  },
  watch: {
    getAssets: {
      deep: true,
      handler: function() {
        this.resetItems();
      }
    },
    locale() {
      this.resetItems();
    }
  }
};
</script>

<style lang="scss">
:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 300px;
}

table th {
  border-left: 1px solid #dddddd;
}
table td {
  border-left: 1px solid #dddddd;
}
th {
  &.border-l-0 {
    border-left: none;
  }

  &.border-r-0 {
    border-right: none;
  }
}
#assetsTable {
  thead tr th {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
  &.list__asset tbody tr:first-child {
    background-color: #44b02a !important;
    color: white !important;

    td {
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
  tbody tr.asset-level {
    background-color: #333f48 !important;
    color: white !important;
    font-weight: 500;
  }

  tr:nth-of-type(2n) {
    background-color: #f5f5f5 !important;
  }
}

.v-data-table tr:not(:first-child) td {
  min-width: 100px;
}
</style>
