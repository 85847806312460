<template>
  <v-container class="fill-height" fluid>
    <template v-if="isMobile">
      <v-card flat width="98%" class="mb-6" v-if="locationList">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card width="100%">
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="slide in documentsImages"
            :key="slide.id"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="10" offset-md="1">
          <v-card class="mr-12" outlined tile>
            <v-carousel
              cycle
              height="330"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="slide in documentsImages"
                :key="slide.id"
                :src="slide.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row align="center" justify="center" class="mt-4" no-gutters>
      <v-col cols="12">
        <v-card>
          <v-card-title class="headline display-1">
            LISTE DES CATEGORIES DE BATIMENTS
          </v-card-title>
          <v-data-table
            locale="fr-FR"
            :headers="filteredHeader"
            :items="filteredItems"
            @click:row="clicked"
            item-key="id"
            :loading="loading"
            class="elevation-1 list__asset"
            id="assetsTable2"
            :page.sync="page"
            sort-by="tickets"
            sort-desc
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:item="{ item }">
              <c-row
                :item="item"
                :headers="filteredHeader"
                rtl
                :class="{
                  'asset-level': item.level === 3 || item.level === 4
                }"
                ignore-null-val
                @click="clicked(item)"
              >
                <template v-slot:tickets="{ item }">
                  {{ item.tickets === 0 ? "-" : item.tickets }}
                </template>
                <template v-slot:technique="{ item }">
                  {{ item.technique === 0 ? "-" : item.technique }}
                </template>
                <template v-if="isMobile" v-slot:etageportefield="{ item }">
                  {{
                    item.level === 3 || item.level === 4
                      ? `${item.town ? item.town : ""} (${
                          item.postcode ? formatCP(item.postcode) : ir
                        })`
                      : item.etageportefield
                  }}
                </template>
                <template v-else v-slot:etageportefield="{ item }">
                  {{
                    item.level === 3 || item.level === 4
                      ? `${item.address ? item.address : ""} ${
                          item.postcode ? item.postcode : ""
                        } ${item.town ? item.town : ""}`
                      : item.etageportefield
                  }}
                </template>
                <template v-slot:events="{ item }">
                  <div
                    class="incident description"
                    v-if="item.events"
                    v-html="item.events.split('<div></div><br/>').join('')"
                  ></div>
                </template>
              </c-row>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { loadStates, permissions, formatCP, sumBy, flatten } from "../utils";
import { UserSessionData } from "../mixins/userData";
import Row from "../components/Row";
import MapView from "../components/Map";

const header = {};

export default {
  name: "Ccctassets",
  components: { [Row.name]: Row, MapView },
  data() {
    return {
      treeData: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 30,
      items: [{ ...header }]
    };
  },
  mixins: [UserSessionData],
  computed: {
    ...mapGetters([
      "getEntityLoadStatus",
      "getUserProfileStatus",
      "getTreeData",
      //"getCharges",
      "getAllTickets",
      "getUserProfiles",
      "getDocuments",
      "getDocumentsImages",
      "getReservations",
      "getAssets"
    ]),
    filteredHeader() {
      let headers = [
        {
          text: "NOM",
          align: "left",
          sortable: false,
          value: "label",
          class: "primary white--text"
        }
      ];

      if (
        this.getUserProfiles &&
        permissions.profilePermission("generique", this.getUserProfiles)
      ) {
        headers.push();
        /*{ tout ça allait dans le header push
            text: "",
            align: "center",
            sortable: false,
            value: "surfacemtwofield",
            class: "border-r-0 primary white--text"
          },
          {
            text: "INFO",
            align: "center",
            sortable: false,
            value: "nomcommercialfield",
            class: "border-l-0 primary white--text"
          },
          {
            text: "LOCALISATION",
            align: "left",
            sortable: false,
            value: "address",
            class: "border-l-0 primary white--text"
          }*/
      }
      /*
      if (
        this.getUserProfiles &&
        permissions.profilePermission("engagement", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "ENGAGEMENT",
            align: "center",
            sortable: false,
            value: "datedbutdebailfield",
            class: "primary white--text"
          },
          {
            text: "DU BAIL",
            align: "left",
            sortable: false,
            value: "datefindebailfield",
            class: "border-l-0 primary white--text"
          }
        );
      }

      if (
        this.getUserProfiles &&
        permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: "",
            align: "center",
            sortable: false,
            value: "loyerannuelhtfield",
            class: "primary white--text"
          },
          {
            text: "FINANCIER",
            align: "center",
            sortable: false,
            value: "charge",
            class: "border-l-0 primary white--text"
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "impot",
            class: "border-l-0 primary white--text"
          }
        );
      }

      headers.push(
        {
          text: "",
          align: "center",
          sortable: false,
          value: "travaux1",
          class: "primary white--text"
        },
        {
          text: "TRAVAUX",
          align: "center",
          sortable: false,
          value: "investissement",
          class: "border-l-0 primary white--text"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "travaux2",
          class: "border-l-0 primary white--text"
        }
      );
*/
      headers.push(
        {
          text: "INCIDENTS EN COURS",
          align: "right",
          sortable: false,
          value: "tickets",
          class: "primary white--text"
        },
        {
          text: "RÉSOLUS",
          align: "right",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text"
        },
        {
          text: "PROCHAINS EVENTS",
          align: "left",
          sortable: false,
          value: "events",
          class: "primary white--text"
        }
      );
      return headers;
    },
    loading() {
      return this.getEntityLoadStatus === loadStates.PENDING;
    },
    filteredItems() {
      const assetID = this.$route.params.assetID;
      let items = [];
      items.push(this.items[0]);
      if (assetID) {
        const items2 = this.items.filter(el => el.entities_id == assetID);
        items2.forEach(item => {
          const children = this.items.filter(el => el.entities_id == item.id);
          items.push(item);
          children.forEach(child => {
            items.push(child);
          });
        });
      } else {
        items = this.items;
      }
      return items.filter(
        item => item.partiescommunefield !== 1 && item.level === 4
      );
    },
    treeAssetData() {
      const firstAsset = this.getTreeData[0];
      return firstAsset.level === 2
        ? flatten(this.getTreeData.map(child => child.children))
        : this.getTreeData;
    },
    locationList() {
      const assetID = this.$route.params.assetID;
      const entities = assetID
        ? this.treeAssetData.find(el => el.id === +assetID).children
        : this.treeAssetData;
      return entities
        .filter(el => el.level === 4)
        .map(el => {
          const latlng = this.getLatLng(el);
          return {
            name: el.name,
            lat: latlng.lat,
            lng: latlng.lng,
            link: { name: "assets.show", params: { id: el.id } }
          };
        });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    documentsImages() {
      const assetID = this.$route.params.assetID;
      if (assetID) {
        const asset = this.treeAssetData.find(el => el.id === +assetID);
        const entitiesId = asset.children.map(entity => entity.id);
        return this.getDocumentsImages.filter(doc => {
          return (
            doc.entities_id === assetID ||
            entitiesId.includes(doc.entities_id) ||
            doc.entities_id === 1
          );
        });
      }
      return this.getDocumentsImages;
    }
  },
  methods: {
    formatCP,
    ...mapActions(["loadEntity", "loadUserProfile", "loadEntities"]),
    clicked: function(row) {
      if (!row.name) {
        return;
      } else if (row.level === 5) {
        this.$router.push({ name: "lots.show", params: { id: row.id } });
      } else if (row.level === 4) {
        this.$router.push({ name: "assets.show", params: { id: row.id } });
      } else {
        this.$router.push({
          name: "assets.index",
          params: { assetID: row.id }
        });
      }
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    getChildrenData(entity, key) {
      const filteredChild = entity.children.filter(
        child => child.partiescommunefield === 0
      );
      return sumBy(filteredChild, key);
    }
  },
  mounted() {
    this.getAssets.forEach(el => {
      this.items.push(el);
    });
  },
  watch: {
    getAssets: {
      deep: true,
      handler: function() {
        this.items = [{ ...header }];
        this.getAssets.forEach(el => {
          this.items.push(el);
        });
      }
    }
  }
};
</script>

<style lang="scss">
:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 300px;
}

table th {
  border-left: 1px solid #dddddd;
}
table td {
  border-left: 1px solid #dddddd;
}
th {
  &.border-l-0 {
    border-left: none;
  }

  &.border-r-0 {
    border-right: none;
  }
}
#assetsTable2 {
  thead tr th {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
  &.list__asset tbody tr:first-child {
    background-color: white;
    color: black;

    td {
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
  tbody tr.asset-level {
    background-color: white;
    color: black;
    font-weight: 500;
  }

  tr:nth-of-type(2n) {
    background-color: #f5f5f5 !important;
  }
}
</style>
