<template>
  <v-row v-if="isMobile" align="start" class="fill-height" no-gutters>
    <v-col cols="12" class="px-0 px-md-0">
      <v-dialog
        ref="crudDialog"
        v-model="addEventDialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mb-n12" color="primary" dark v-bind="attrs" v-on="on">
            AJOUTER UNE DEMANDE
          </v-btn>
        </template>
        <add-support-form
          :overlay="overlay"
          @canceled="closeEventDialog"
          @addSupport="addSupport"
        ></add-support-form>
      </v-dialog>
      <list :tickets="getTickets"></list>
    </v-col>
  </v-row>
  <v-row v-else align="start" class="fill-height mx-6" no-gutters>
    <v-col cols="12" class="px-0 px-md-0">
      <v-dialog
        ref="crudDialog"
        v-model="addEventDialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mb-n12" color="primary" dark v-bind="attrs" v-on="on">
            AJOUTER UNE DEMANDE
          </v-btn>
        </template>
        <add-support-form
          :overlay="overlay"
          @canceled="closeEventDialog"
          @addSupport="addSupport"
        ></add-support-form>
      </v-dialog>
      <list :tickets="getTickets"></list>
    </v-col>
  </v-row>
</template>
<script>
import { getColor, IMAGE_TYPE } from "../utils";
import List from "./List";
import USER_API from "../api/user";
import AddSupportForm from "../components/support/AddSupport.vue";
import TICKET_API from "../api/ticket";
import Swal from "sweetalert2";
import axios from "../plugins/axios";

import { mapGetters } from "vuex";

export default {
  name: "AssetAdmin",
  props: {
    note: {
      type: String,
      default: null
    }
  },
  data: () => ({
    overlay: false,
    addEventDialog: false
  }),
  components: { List, AddSupportForm },
  methods: {
    addSupport({ input }) {
      //let urlcourante = document.location.href;
      //let ent = urlcourante.split("/")[5];
      //let it = urlcourante.split("/")[9];
      //let itID = urlcourante.split("/")[11];
      let it = "location";
      let ent = input.lieux.split(/[@()]+/)[1];
      let itID = input.lieux.split(/[@()]+/)[2];
      const cats = [
        ["Exploitation", 1],
        ["Commissionnement", 2],
        ["I feel Good", 3],
        ["Projet", 4],
        ["Prestation", 5],
        ["Acces", 6],
        ["Equipement", 7],
        ["Public", 8],
        ["BUG", 9],
        ["FEAT", 10]
      ];
      let cat = cats.find(element => element[0] === input.category)[1];
      const payload = {
        name: JSON.stringify({
          itemtype: it,
          itemtypeID: itID,
          titre: input.title,
          mail: input.email,
          tel: input.tel
        }),
        requesttypes_id: 7,
        type: 1,
        locations_id: itID,
        itilcategories_id: cat,
        content: input.content,
        entities_id: ent,
        is_visible: 1
      };

      this.overlay = true;
      USER_API.setActiveEntity(ent).then(() => {
        TICKET_API.createTicket(payload)
          .then(({ data }) => {
            this.photoLoading = true;
            this.overlay = true;
            const image = input.exifimg;
            let ticketid = data.id;
            TICKET_API.addImageDoc(image, input.photo.src)
              .then(({ data }) => {
                TICKET_API.attachDocumentToItem(ticketid, data.id)
                  .then(async () => {
                    this.getDocumentById(data.id);
                  })
                  .catch(({ response }) => {
                    this.commentError = response.data;
                    this.setError(response.data[1]);
                    this.photoLoading = false;
                    this.overlay = false;
                  });
              })
              .catch(({ response }) => {
                axios.defaults.headers["Content-Type"] = "application/json";
                this.commentError = response.data;
                this.setError(response.data[1]);
              });
          })
          .then(() => {
            this.addEventDialog = false;
            this.overlay = false;
            if (this.isDirectLink) {
              Swal.fire({
                title: "Merci de votre demande!",
                text: "Vous allez recevoir un mail de suivi",
                icon: "success",
                showConfirmButton: false
              });
            } else {
              Swal.fire(
                "Merci de votre demande!",
                "Vous allez recevoir un mail de confirmation",
                "success"
              );
            }
          })
          .finally(() => {
            if (this.isDirectLink) {
              setTimeout(function() {
                window.location.reload();
              }, 4000);
            }
          })
          .catch(err => {
            this.addEventDialog = false;
            this.overlay = false;
            Swal.fire({
              icon: "error",
              title: "Oops... pas top",
              html:
                err && err.response && err.response.data
                  ? err.response.data
                  : "Error de creation"
            });
          });
      });
    },
    closeEventDialog() {
      this.addEventDialog = false;
    },
    async getDocumentById(documentID) {
      //Get the document meta data
      let documentData = USER_API.getDocument(documentID);
      //Get the document file in blob
      let documentImage = USER_API.getDocument(documentID, true);
      // CCA modif l'affichage du nom des rédacteurs de photos
      axios.all([documentData, documentImage]).then(
        axios.spread(async (docData, docImg) => {
          let iTFollowUp = docData.data;
          USER_API.getUserData(iTFollowUp.users_id).then(async ({ data }) => {
            iTFollowUp.user_name = data.firstname.concat(" ", data.realname);
            iTFollowUp.color = getColor();
            iTFollowUp.actionType = IMAGE_TYPE;
            iTFollowUp.img = URL.createObjectURL(docImg.data);
            iTFollowUp.doc = docImg.data;
            this.$emit("new-comment", iTFollowUp);
            this.setSuccessState();
            this.overlay = false;
            this.closeEventDialog();
          });
        })
      );
    },
    setError(errorMsg) {
      this.error = {
        message: errorMsg
      };
      setTimeout(() => {
        this.error = undefined;
      }, 5000);
    },
    setSuccessState() {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    }
  },
  computed: {
    ...mapGetters(["getAllTickets", "getEquipments", "getActiveEntity"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    getTickets() {
      return this.getAllTickets.filter(
        ticket =>
          [1, 9, 10].includes(ticket.itilcategories_id) && ticket.status < 5
      );
    }
  }
};
</script>
